import commonHelper from '@/app/utility/common.helper.utility';
import addEvent from './addEvent';
import { ModelSelect } from 'vue-search-select';
import SearchModule from '../../admin/responsibility/searchModule';
export default {
  name: 'applicationCriteria',
  components: { addEvent, ModelSelect, SearchModule },
  props: { showEventsModal: Boolean },
  watch: {
    currentPage: function() {
      this.getEventList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEventList();
    }
  },
  data() {
    return {
      moduleName: null,
      moduleId: null,
      showModuleForm: false,
      appCriteria: null,
      eventName: null,
      active: null,
      timeOut: null,
      showAddGroup: false,
      payload: {},
      unsubscribe: null,
      ruleName: null,
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      eventList: [],
      eventFeilds: [
        {
          key: 'event_name'
        },
        {
          key: 'module_name'
        },
        {
          key: 'table_name'
        },
        {
          key: 'column_name'
        },
        {
          key: 'email'
        },
        {
          key: 'sms',
          label: 'SMS'
        },
        {
          key: 'auto_approval'
        }
      ]
    };
  },
  mounted() {
    this.getEventList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddGroup = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'workflow/getWorkflowEventList',
            'workflow-event',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getEventList() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.moduleId,
        event_name: this.eventName
      };
      this.$store
        .dispatch('workflow/getWorkflowEventList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.eventList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.appCriteria = null;
      this.eventName = null;
      this.moduleId = null;
      this.moduleName = null;
      this.getEventList();
    },
    hideGroupModal() {
      this.showAddGroup = false;
    },
    rowSelected(item) {
      if (this.showEventsModal) {
        this.$emit('selectedworkflowEvent', item);
      } else {
        this.showAddGroup = true;
        this.timeOut = setTimeout(() => {
          this.eventBus.$emit('groupDetail', item);
        }, 0);
      }
    },
    selectedSearchModule(module) {
      this.moduleName = module.module_name;
      this.moduleId = module.module_id;
      this.showModuleForm = false;
    },
    showHideModuleForm(flag) {
      this.showModuleForm = flag;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    this.unsubscribe();
  }
};
