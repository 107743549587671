import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import ApproverEmployeeList from '../../admin/employee';
import DelegatedEmployeeList from '../../admin/employee';
import Event from '../workflowEvent';
export default {
  name: 'UpdateWorkflow',
  components: {
    DatePicker,
    ApproverEmployeeList,
    DelegatedEmployeeList,
    Event
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showEmployeeModal: false,
      showValueSetModal: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      event: null,
      doc_num: {
        value: null,
        text: null
      },
      approver_emp: {
        value: null,
        text: null
      },
      from_date: null,
      to_date: null,
      start_date_end_date: [],
      delegated_emp: {
        value: null,
        text: null
      },
      tab: null,
      updateWorkflowList: [],
      updateWorkflowFields: [
        {
          key: 'event'
        },
        {
          key: 'document_number'
        },
        {
          key: 'delegated_from'
        },
        {
          key: 'delegated_to'
        },
        {
          key: 'status'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    setChildName(tab) {
      if(tab === 'Event'){
        return this.childName = 'Event'
      }
      else if(tab === 'ApproverEmployeeList'){
        return this.childName = 'Approver Employee List'
      }
      else if(tab === 'DelegatedEmployeeList'){
        return this.childName = 'Delegated Employee List'
      }
    },
    showHideEmpModal(flag, component) {
      this.showEmployeeModal = flag;
      this.tab = component;
      this.setChildName(this.tab);      
    },
    selectedEmployee(item) {
      if (this.tab === 'Event') {
        this.event = item.event_name;
      } else if (this.tab === 'ApproverEmployeeList') {
        this.approver_emp = {
          value: item.employee_id,
          text: item.name
        };
      } else if (this.tab === 'DelegatedEmployeeList') {
        this.delegated_emp = {
          value: item.employee_id,
          text: item.name
        };
      }
      this.showEmployeeModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      this.doc_num.value = item.value_code;
      this.doc_num.text = item.doc_num;
      this.showValueSetModal = false;
    },
    rowSelected() {

    },
    clearVsetValues(vsetCode){
      if(vsetCode==='WF_DOC_NUM')
      this.doc_num.value = null;
      this.doc_num.text = null;
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
  }
};
