import { required } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import SearchModule from '../../../admin/responsibility/searchModule';
import workFlowRule from '../../workFlowRule';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store';
export default {
  name: 'addEvent',
  components: {
    ModelSelect,
    DatePicker,
    SearchModule,
    workFlowRule
  },
  data() {
    return {
      setTimeVsetCode: null,
      tablePrameter: null,
      eventListIndex: null,
      showRuleModal: false,
      showModuleForm: false,
      parent_value_set_id: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      groupListIndex: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      eventForm: {
        event_id: null,
        event_name: null,
        module_id: null,
        module_name: null,
        email: false,
        sms: false,
        table_name: null,
        column_name: null,
        auto_approval: false,
        event_details: [
          {
            active: true,
            start_date: null,
            end_date: null,
            rule_name: null,
            rule_id: null,
            event_rule_mpng_id: null
          }
        ]
      },
      eventDetailFeilds: [
        {
          key: 'rule_name'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
       
        {
          key: 'active'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    eventForm: {
      module_name: { required },
      event_name: {
        required
      }
    }
  },
  mounted() {
    this.getLegalEntity();
    this.eventBus.$on('groupDetail', groupDetail => {
      this.getEventDetail(groupDetail.event_id);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditEvent();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {

          this.eventBus.$emit('commonUpload', { id: this.eventForm.event_id});

        }
      }
    });
  },
  methods: {
    addEditEvent() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const eventDetail = this.eventForm.event_details.map(detail => {
          return {
            active: detail.active,
           
            event_rule_mpng_id: detail.event_rule_mpng_id,
            rule_id: detail.rule_id,
            start_date: commonHelper.formattedDate(detail.start_date_end_date[0]),
            end_date: commonHelper.formattedDate(detail.start_date_end_date[1]),
          
          };
        });
        const payload = {
          addUpdateEventsLists: eventDetail,
          auto_approval: this.eventForm.auto_approval,
          column_name: this.eventForm.column_name,
          email: this.eventForm.email,
          event_id: this.eventForm.event_id,
          event_name: this.eventForm.event_name,
          module_id: this.eventForm.module_id,
          sms: this.eventForm.sms,
          table_name: this.eventForm.table_name
        };
        this.loader = true;
        this.$store
          .dispatch('workflow/addEditEvent', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getEventDetail(eventId) {
      this.loader = true;
      this.$store
        .dispatch('workflow/getEventDetail', eventId)
        .then(response => {
          this.editMode = false;
          this.loader = false;
          if (response.status === 200) {
            this.eventForm = response.data.data;
            const result = response.data.data.event_details.map(detail => {
              detail.start_date = detail.start_date? new Date(detail.start_date): null;
              detail.end_date = detail.end_date? new Date(detail.end_date): null;
              return detail;
            });
            this.eventForm.event_details = result.map(detail => {
              detail.start_date_end_date = [
                  new Date(detail.start_date),
                  new Date(detail.end_date)
                ];
              return detail;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store.dispatch('receipt/getOrganizationLov', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const leagalEntity = results.map(type => {
            return {
              value: type.org_id,
              text: type.org_name
            };
          });
          this.legalEntityList = leagalEntity;
        }
      });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'SYSTEM_TABLES') {
        this.eventForm.table_name = item.table_name;
      } else {
        this.eventForm.column_name = item.column_name;
      }
    },
    openValueSetModal(vsetCode) {
      this.tablePrameter = null;
      if (vsetCode === appStrings.VALUESETTYPE.TABLE_COLUMNS) {
        this.tablePrameter = this.eventForm.table_name;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.eventForm.event_details.push({
          active: false,
          start_date: null,
          end_date: null,
          rule_name: null,
          rule_id: null,
          event_rule_mpng_id: null
        });
      }
    },
    removeRow(index) {
      this.eventForm.event_details.splice(index, 1);
    },
    selectedSearchModule(module) {
      this.eventForm.module_name = module.module_name;
      this.eventForm.module_id = module.module_id;
      this.showModuleForm = false;
    },
    showHideModuleForm(flag) {
      this.showModuleForm = flag;
    },
    showHideRuleModal(flag, index) {
      this.showRuleModal = flag;
      this.eventListIndex = index;
    },
    selectedRules(item) {
      this.eventForm.event_details[this.eventListIndex].rule_id =
        item.rule_hdr_id;
      this.eventForm.event_details[this.eventListIndex].rule_name =
        item.rule_name;
      this.showRuleModal = false;
    },
    rowSelected() {},
    clearVsetValues(vsetCode) {
      if (vsetCode === 'SYSTEM_TABLES') {
        this.eventForm.table_name = null;
      } else {
        this.eventForm.column_name = null;
      }
    }
  
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.eventBus.$off('groupDetail');
    this.unsubscribe();
  }
};
